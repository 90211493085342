<template>
  <div />
</template>

<script>
import locations from './company-locations';

export default {
  name: 'Locations',

  mounted() {
    this.$el.append(locations());
  },
};
</script>

<style></style>
