import { on, st } from '../ui/dom';

const expandToWindowBottom = (element) => {
  const rect = element.getBoundingClientRect();
  const elementY = rect.y || rect.top;
  const wHeight = window.innerHeight;
  st(element, {
    height: wHeight - elementY + 'px',
  });
};

const hugBottom = (element, continuous) => {
  expandToWindowBottom(element);

  if (continuous) {
    on(window, ['resize', 'scroll'], () => expandToWindowBottom(element));
  }
};

export default hugBottom;
