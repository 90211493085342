import is from './is';

const emptyObject = (object) => {
  Object.keys(object).forEach((key) => delete object[key]);
};

const emptyArray = (array) => {
  while (array.length > 0) {
    array.pop();
  }
};

/**
 * Empties a an object or array for values
 *
 * @param {object|array} variable
 */
const empty = (variable) => {
  if (is.array(variable)) {
    emptyArray(variable);
  } else {
    emptyObject(variable);
  }
};

export default empty;
