import { ac, dc } from './dom';

const appearClassName = 'u-appear';

/**
 * Adds an appearing animation to an element, e.g. when adding an element to a list
 *
 * @param {HTMLDomElement} element the element to appear
 */
const appear = (element) => {
  ac(element, appearClassName);
  setTimeout(() => dc(element, appearClassName), 1400);
};

export default appear;
