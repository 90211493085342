import { cr, ap, on, ac, dc } from '@/lib/ui/dom';
import { translate as t } from '@/util/i18n';
import map from '@/map/map';
import button from '@/lib/ui/button';
import textInput from '@/lib/ui/textInput';
import confirmDialog from '@/components/dialog/confirmDialog';
import api from '@/util/api';
import i from '@/lib/ui/i';
import empty from '@/lib/util/empty';
import is from '@/lib/util/is';
import hugBottom from '@/lib/util/hugBottom';
import appear from '@/lib/ui/appear';
import errorMessage from '@/util/errorMessage';
import messageDialog from '@/components/dialog/messageDialog';
import emptyState from '@/lib/ui/emptyState';
import mapTools from '@/map/mapTools';

const locations = () => {
  const locationsData = {};

  let currentLocation;
  let newlyCreatedLocationId;

  const locationsMap = map({
    search: true,
  });
  const mapWrapper = cr('section', 'c-locations-map');

  const openMap = () => {
    ac(mapWrapper, 'open');
    locationsMap.redrawTiles();
  };
  const closeMap = () => dc(mapWrapper, 'open');

  const locationList = cr('ol', 'c-location-list u-list-style-none-compact');

  const locationInfo = cr('div', 'c-location-info');
  const locationName = cr('h3', 'c-location-field c-location-name');
  const locationDesc = cr('p', 'c-location-field c-location-description');

  const onChangeLocationEdit = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    currentLocation[key] = value;
    locationName.innerHTML = currentLocation.name;
    locationDesc.innerHTML = currentLocation.description;
    saveButton.disabled = currentLocation.name.length < 3;
  };

  const locationForm = cr('div', 'c-location-form');

  const locationNameInput = textInput(
    {
      labelText: t('name'),
      className: 'c-location-field',
      onChange: onChangeLocationEdit,
    },
    {
      name: 'name',
    },
  );
  const locationDescriptionInput = textInput(
    {
      labelText: t('description'),
      className: 'c-location-field',
      onChange: onChangeLocationEdit,
    },
    {
      name: 'description',
    },
  );

  const editLocation = (newLocation) => {
    dc(locationInfo, 'show');
    ac(locationForm, 'show');

    if (newLocation === true) {
      locationsMap.clearDrawing();
      locationNameInput.setValue('');
      locationDescriptionInput.setValue('');
    } else {
      locationNameInput.setValue(currentLocation.name);
      locationDescriptionInput.setValue(currentLocation.description);
    }

    tools.show();
  };

  const cancelEdit = () => {
    dc(locationForm, 'show');
    // ac(locationInfo, 'show');

    if (!is.defined(currentLocation.id)) {
      closeMap();
    }

    tools.hide();
    locationsMap.clearDrawing();
    locationsMap.setCurrentDrawingFromString(currentLocation.geofence);
  };

  const saveLocation = () => {
    // TODO: Show saving

    const locationString = locationsMap.getCurrentDrawingAsString();

    if (is.defined(locationString)) {
      currentLocation.geofence = locationString;
      if (is.defined(currentLocation.id)) {
        api
          .updateLocation(currentLocation)
          .then(() => {
            tools.hide();
            dc(locationForm, 'show');
            ac(locationInfo, 'show');
            updateLocations();
            // TODO: Notify success
          })
          .catch(() => {
            // TODO: handle error
          });
      } else {
        api
          .createLocation(currentLocation)
          .then((res) => {
            tools.hide();
            newlyCreatedLocationId = res.locationId;
            dc(locationForm, 'show');
            ac(locationInfo, 'show');
            closeMap();
            updateLocations();
            // TODO: Notify success
          })
          .catch(() => {
            // TODO: handle error
          });
      }
    } else {
      messageDialog(t('missingGeofence'), t('missingGeofenceMsg'), t('ok'), 'warning').open();
    }
  };

  const addLocation = () => {
    currentLocation = {};
    editLocation(true);
    openMap();
  };

  let currentActive;

  const openLocation = (locationId, alsoOpenMap) => {
    if (is.defined(currentActive)) {
      dc(currentActive, 'active');
    }
    currentActive = document.querySelector('.c-location--' + locationId);
    ac(currentActive, 'active');

    currentLocation = locationsData[locationId];
    locationName.innerHTML = currentLocation.name;
    if (is.strNotEmpty(currentLocation.description)) {
      locationDesc.innerHTML = currentLocation.description;
    } else {
      locationDesc.innerHTML = t('noDescription');
    }

    ac(locationInfo, 'show');
    dc(locationForm, 'show');

    locationsMap.loaded.then(() => {
      tools.hide();
      locationsMap.clearDrawing();
      locationsMap.setCurrentDrawingFromString(currentLocation.geofence);
    });

    if (alsoOpenMap) {
      openMap();
    }
  };

  const removeLocation = (locationId) => {
    confirmDialog(
      t('removeLocation'),
      t('removeLocationMsg', null, {
        locationName: locationsData[locationId].name,
      }),
      t('cancel'),
      t('remove'),
    )
      .open()
      .then((confirm) => {
        if (confirm) {
          api
            .deleteLocation(locationId)
            .then(() => {
              currentLocation = {};
              tools.hide();
              dc(locationForm, 'show');
              ac(locationInfo, 'show');
              locationsMap.clearDrawing();
              updateLocations();
            })
            .catch((res) =>
              errorMessage(t('removeLocationError'), t('removeLocationErrorMsg'), res),
            );
        }
      });
  };

  const updateLocations = () => {
    api
      .getLocations()
      .then((res) => {
        const locations = res.locations;
        hugBottom(locationsMap.element, true);
        locationsMap.redrawTiles();

        empty(locationsData);
        locations.forEach((location) => {
          locationsData[location.id] = location;
          if (currentLocation && currentLocation.id === location.id) {
            currentLocation = location;
          }
        });

        locationList.innerHTML = '';
        if (locations.length < 1) {
          ap(
            locationList,
            emptyState(
              t('noLocations'),
              t('noLocationsMsg'),
              '/static/images/empty-states/locations.svg',
            ),
          );
        }
        ap(
          locationList,
          locations.map((location) => {
            const locationItem = cr('li', 'c-location c-location--' + location.id);
            const locationName = cr('h3', 'c-location-title u-typ-body-1', location.name);

            if (location.id === newlyCreatedLocationId) {
              appear(locationItem);
            }

            const removeLocationButton = button((event) => {
              event.stopPropagation();
              removeLocation(location.id);
            });

            on(locationItem, 'click', () => openLocation(location.id, true));

            return ap(locationItem, locationName, ap(removeLocationButton, i('trash')));
          }),
        );

        if (newlyCreatedLocationId) {
          openLocation(newlyCreatedLocationId);
        } else if (currentLocation) {
          openLocation(currentLocation.id);
        } else {
          openLocation(locations[0].id);
        }

        newlyCreatedLocationId = false;
      })
      .catch(() => {
        hugBottom(locationsMap.element, true);
        locationsMap.redrawTiles();

        // TODO: Handle error
      });
  };

  updateLocations();

  on(window, 'keydown', (event) => {
    if (event.key === 'Escape') {
      closeMap();
    }
  });

  const hideTools = (drawing) => {
    if (!drawing) {
      locationsMap.clearDrawing();
      locationsMap.setCurrentDrawingFromString(currentLocation.geofence);
    }
  };

  const tools = mapTools(locationsMap, hideTools);

  tools.setTools({
    polygon: true,
    circle: true,
  });

  const cancelEditButton = button(cancelEdit, 'type-secondary', t('cancel'));
  const saveButton = button(saveLocation, null, t('save'));

  return ap(
    cr('section', 'c-locations'),
    ap(
      cr('section', 'c-locations-administration'),
      ap(button(addLocation, 'c-add-location-button'), i('plus'), t('addLocation')),
      locationList,
    ),
    ap(
      mapWrapper,
      ap(
        cr('div', 'c-map-header'),
        ap(locationInfo, locationName, locationDesc, ap(button(editLocation, 'circle'), i('pen'))),
        ap(
          locationForm,
          locationNameInput.element,
          locationDescriptionInput.element,
          cancelEditButton,
          saveButton,
        ),
        ap(button(closeMap, 'c-close-map-button'), i('times')),
      ),
      ap(locationsMap.element, tools.element),
    ),
  );
};

export default locations;
